exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-blog-js": () => import("./../../../src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-blog-wp-category-slug-js": () => import("./../../../src/pages/blog/{WpCategory.slug}.js" /* webpackChunkName: "component---src-pages-blog-wp-category-slug-js" */),
  "component---src-pages-blog-wp-post-slug-js": () => import("./../../../src/pages/blog/{WpPost.slug}.js" /* webpackChunkName: "component---src-pages-blog-wp-post-slug-js" */),
  "component---src-pages-en-customer-zone-js": () => import("./../../../src/pages/en/customer-zone.js" /* webpackChunkName: "component---src-pages-en-customer-zone-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-kontakt-js": () => import("./../../../src/pages/kontakt.js" /* webpackChunkName: "component---src-pages-kontakt-js" */),
  "component---src-pages-o-spolocnosti-js": () => import("./../../../src/pages/o-spolocnosti.js" /* webpackChunkName: "component---src-pages-o-spolocnosti-js" */),
  "component---src-pages-outsourcing-projektov-a-it-kapacit-js": () => import("./../../../src/pages/outsourcing-projektov-a-it-kapacit.js" /* webpackChunkName: "component---src-pages-outsourcing-projektov-a-it-kapacit-js" */),
  "component---src-pages-pripadove-studie-js": () => import("./../../../src/pages/pripadove-studie.js" /* webpackChunkName: "component---src-pages-pripadove-studie-js" */),
  "component---src-pages-programovanie-na-mieru-js": () => import("./../../../src/pages/programovanie-na-mieru.js" /* webpackChunkName: "component---src-pages-programovanie-na-mieru-js" */),
  "component---src-pages-referencia-mysql-projects-url-js": () => import("./../../../src/pages/referencia/{MysqlProjects.url}.js" /* webpackChunkName: "component---src-pages-referencia-mysql-projects-url-js" */),
  "component---src-pages-referencie-js": () => import("./../../../src/pages/referencie.js" /* webpackChunkName: "component---src-pages-referencie-js" */),
  "component---src-pages-vyvoj-a-programovanie-it-systemov-js": () => import("./../../../src/pages/vyvoj-a-programovanie-it-systemov.js" /* webpackChunkName: "component---src-pages-vyvoj-a-programovanie-it-systemov-js" */),
  "component---src-pages-vyvoj-aplikacii-a-programov-na-mieru-js": () => import("./../../../src/pages/vyvoj-aplikacii-a-programov-na-mieru.js" /* webpackChunkName: "component---src-pages-vyvoj-aplikacii-a-programov-na-mieru-js" */),
  "component---src-pages-web-na-mieru-js": () => import("./../../../src/pages/web-na-mieru.js" /* webpackChunkName: "component---src-pages-web-na-mieru-js" */),
  "component---src-pages-webdizajn-pre-eshopy-a-webstranky-js": () => import("./../../../src/pages/webdizajn-pre-eshopy-a-webstranky.js" /* webpackChunkName: "component---src-pages-webdizajn-pre-eshopy-a-webstranky-js" */),
  "component---src-pages-zakaznicka-zona-js": () => import("./../../../src/pages/zakaznicka-zona.js" /* webpackChunkName: "component---src-pages-zakaznicka-zona-js" */)
}

